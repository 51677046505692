/*******************************
Variables
*******************************/
@use "sass:color";

// Fonts

$font-family-mono: "PT Mono", Courier, monospace;
$font-family-rubik: "Rubik", "Helvetica", sans-serif;
$font-family-lexend: "Lexend", "Helvetica", sans-serif;

$thin: 100;
$xlight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$xbold: 800;
$black: 900;

// Global Sizes

$header-height: 52px;
$header-inset: 10px;
$session-header-height: 128px;
$tabbar-height: 80px;
$content-width: 1200px;
$content-width-narrow: 640px;
$side-padding: 40px;
$chat-max-width: 920px;
$mascot-sidebar-width: 280px;
$references-drawer-width: 380px;
$mascot-list-gutter: 10px;

// Colors

$color-off-white: #f7f7f7;
$color-silver: #f0f1f2;
$color-light-grey: #e6e6e6;
$color-medium-grey: #c2c2c2;
$color-dark-blue: #062533;
$color-slate: #334e59;

$color-action: #2de279;
$color-positive: #3dbfbf;
$color-warn: #ff8300;
$color-warn-bg: #fff9f1;
$color-danger: #e42d2d;

$identity-blue: #010e2d;
$identity-orange: #f0941d;

$color-bg-dark: #010c12;
$color-border-light: #e8ebed;
$color-border: #e0e0e0;
$color-border-dark: $color-slate;

$color-text-lighter: #aeaeae;
$color-text-light: #707070;
$color-text: #313131;
$color-text-dark: #000000;
$color-link: $color-positive;
//$color-field-hover: lighten($color-positive, 45%);
$color-field-hover: color.adjust($color-positive, $lightness: 45%);

$color-progress-bar: $color-action;

$color-rank-positive: #2ad496;
$color-rank-negative: #f7652d;

$color-white: #fff;
$color-new-request-bg: #f2f3f7;
$color-stepper: #c5cbd7;
$color-orange-btn: #f0941e;
$color-orange-btn-light: #ffbe6a;
