@use "vars";

.request-details {
  .action-box {
    border: 2px solid black;

    &.waiting {
      border-color: #FF9000;
    }

    &.approved {
      border-color: #00BA29;
    }

    &.rejected {
      border-color: #E10101;
    }
  }
}