/*******************************
Global Classes
*******************************/
@use "vars";

.meta,
label {
  color: vars.$color-text-light;
  font-size: 13px;
  font-weight: vars.$medium;

  &.small {
    font-size: 12px;
    font-weight: vars.$normal;
  }

  &.large {
    font-size: 14px;
  }

  &.light {
    color: rgba(white, .65);
  }

  &.white {
    color: white;

    > i {
      color: white;
    }
  }
}

a.meta {
  color: vars.$color-link;
}

.error,
.success {
  display: block;
  font-size: 13px;
  font-weight: vars.$medium;
  margin: 15px 0;
}

.warning {
  color: vars.$color-warn;
}

.error {
  color: vars.$color-danger;
}

.danger:not(button):not(.button) {
  color: vars.$color-danger !important;

  * {
    color: vars.$color-danger !important;
  }
}

.success {
  color: vars.$color-action;
}

.icon-close {
  color: vars.$color-text-light;

  &:hover {
    color: vars.$color-text-dark;
  }
}

.alert {
  background-color: vars.$color-bg-dark;
  border: 1px solid vars.$color-border-dark;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 20px 0;

  > * {
    color: rgba(white, .85);
    margin: 0;
  }

  &.org-alert {
    margin-top: 0;

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
  }
}

.code-snippet-custom {
  display: block;
  font-size: 14px;
  width: 100%;
  word-break: break-all;
  margin: 0;
  max-height: 600px;
  overflow: auto;
  padding-right: 20px;
}

.select-all {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .meta {
    cursor: pointer;
    margin: 0 15px 0 0 !important;

    &:hover {
      color: vars.$color-link;
    }
  }
}

.cursor {
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.bubble {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 24px;
  margin-bottom: 12px;

  > p {
    margin-top: 0;
  }
}

.action-link {
  background-color: #fff;
  border: 1px solid transparent;
  color: vars.$identity-orange;
  font-weight: bold;
  padding: 10px 5px;

  &:hover {
    background-color: #fff;
    border: 1px solid vars.$identity-orange;
  }
}

.radio-group {
  padding: 10px;

  > label {
    display: inline !important;
    margin-right: 30px;

    > input {
      margin-right: 5px !important;
      margin-top: -1px;
      vertical-align: middle;
    }
  }
}

.modal {
  input, textarea {
    padding: 8px !important;
    border: 1px solid vars.$identity-blue !important;
    border-radius: 4px !important;
    margin: 5px 0 !important;
  }

  input:not([type=radio]):not([type=checkbox]) {
    width: 80% !important;
    height: 40px !important;
  }
}

.item-list {
  padding-left: 10px;
  margin-bottom: 2px;

  > li {
    border-radius: 10px;
    background: #F2F3F7;
    padding: 10px;
    list-style-type: none;
    margin-bottom: 1px;

    > span {
      float: right;
      font-style: italic;
      opacity: .6;
    }
  }

}

.Mui-disabled {
  opacity: 0.5;
}