@charset "utf-8";

@font-face {
  font-family: "titan4-icons";
  src: 
    url("../icons/titan4-icons.ttf?n458jy") format("truetype"),
    url("../icons/titan4-icons.woff?n458jy") format("woff"),
    url("../icons/titan4-icons.svg?n458jy#titan4-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class^="icon-"],
i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "titan4-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i[class^="icon-"]::before,
i[class*=" icon-"]::before {
  position: relative;
  margin-top: 2px;
  display: inline-block;
}

.icon-retract:before {
  content: "\e900";
}
.icon-file-info:before {
  content: "\e92f";
}
.icon-copy:before {
  content: "\e92e";
}
.icon-apps:before {
  content: "\e927";
}
.icon-button-embed:before {
  content: "\e928";
}
.icon-expand:before {
  content: "\e929";
}
.icon-globe:before {
  content: "\e92a";
}
.icon-inline-embed:before {
  content: "\e92b";
}
.icon-link:before {
  content: "\e92c";
}
.icon-upload:before {
  content: "\e92d";
}
.icon-ellipses:before {
  content: "\e924";
}
.icon-graph:before {
  content: "\e925";
}
.icon-mascot:before {
  content: "\e926";
}
.icon-behavior:before {
  content: "\e923";
}
.icon-external-link:before {
  content: "\e921";
}
.icon-sort:before {
  content: "\e922";
}
.icon-star:before {
  content: "\e91e";
}
.icon-thumbs-down:before {
  content: "\e91f";
}
.icon-thumbs-up:before {
  content: "\e920";
}
.icon-checkmark:before {
  content: "\e91d";
}
.icon-eye-off:before {
  content: "\e91b";
}
.icon-eye:before {
  content: "\e91c";
}
.icon-account:before {
  content: "\e916";
}
.icon-help:before {
  content: "\e918";
}
.icon-sign-out:before {
  content: "\e919";
  transform: scaleX(-1);
}
.icon-users:before {
  content: "\e91a";
}
.icon-archive:before {
  content: "\e90d";
}
.icon-data:before {
  content: "\e90e";
}
.icon-file:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-new-chat:before {
  content: "\e912";
}
.icon-settings:before {
  content: "\e913";
}
.icon-wand:before {
  content: "\e914";
}
.icon-chevron-up:before {
  content: "\e917";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\e915";
}
.icon-chevron-right:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-edit:before {
  content: "\e905";
}
.icon-embed:before {
  content: "\e906";
}
.icon-filter:before {
  content: "\e907";
}
.icon-lock:before {
  content: "\e908";
}
.icon-mic:before {
  content: "\e909";
}
.icon-qr:before {
  content: "\e90a";
}
.icon-reload:before {
  content: "\e90b";
}
.icon-remove:before {
  content: "\e90c";
}
.icon-share:before {
  content: "\e93e";
}
.icon-warn:before {
  content: "\e93f";
}
