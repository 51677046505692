/*******************************
Global Elements
*******************************/
@use "vars";

html {
  font-family: vars.$font-family-lexend;
  font-size: 15px;
  color: white;
  height: 100%;
}

body {
  overflow: hidden;
  min-height: 100%;
}

#root,
#root > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: vars.$color-text-dark;
  font-weight: vars.$bold;
  margin: 20px 0;
}

h1 {
  font-size: 32px;
  letter-spacing: -0.75px;
  margin: 40px 0;
}

h2 {
  font-size: 28px;
  letter-spacing: -0.66px;
}

h3 {
  font-size: 24px;
  letter-spacing: -0.56px;
}

h4 {
  font-size: 21px;
  font-weight: vars.$semi-bold;
  letter-spacing: -0.49px;
}

h5 {
  font-size: 18px;
  font-weight: vars.$semi-bold;
  letter-spacing: -0.42px;
}

h6 {
  font-size: 16px;
  font-weight: vars.$semi-bold;
  letter-spacing: -0.38px;
}

a {
  cursor: pointer;
  color: vars.$color-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

b,
strong {
  font-weight: vars.$semi-bold;
}

// Icons only
i {
  color: #fff;
}

p {
  font-size: 16px;
  line-height: 1.625;
  margin: 20px 0;

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 18px;
  }
}

p,
.meta {
  > code {
    background-color: vars.$color-light-grey;
    border-radius: 6px;
    padding: 0.2em 0.4em;
    font-size: 85%;
  }
}

code,
pre {
  font-family: vars.$font-family-mono;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 21px;
}

hr {
  border: none;
  border-bottom: 1px solid vars.$color-border;
  margin: 40px 0;

  &.dark {
    border-color: vars.$color-border-dark;
  }
}

table {
  text-align: left;
  border-collapse: collapse;

  th,
  td {
    padding: 10px 15px;
    border: 1px solid rgba(black, 0.1);
  }
}

.request-priority-img {
  height: 30px;
  margin-top: 3px;
}

.modal-add-structure, .modal-add-data-file {
  .radio-group {
    label {
      display: flex;
      align-items: center;

      input[type="radio"], input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
    }
  }
  textarea {
    width: 100%;
  }
}