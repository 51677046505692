/*******************************
Mixins
*******************************/

@mixin content-width() {
  margin: 0 auto;
  width: calc(100% - #{$side-padding * 2});
  max-width: $content-width;
}

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin icons() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "titan4-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &::before {
    // display: block;
    position: relative;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}