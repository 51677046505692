/*******************************
Layout
*******************************/
@use "vars";
@use "mixins";

.header {
  height: 72px;
  background: vars.$identity-blue;
  display: flex;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.sidebar {
  width: 120px;
  background: vars.$identity-blue;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  direction: rtl;
  flex-shrink: 0;

  .sidebar-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 36px;
    cursor: pointer;

    &.active,
    &:hover {
      color: vars.$identity-orange;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    > span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }
  }

  &::-webkit-scrollbar-track {
    background: #1c2b49;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1c2b49;
  }

  &::-webkit-scrollbar-thumb {
    background-color: vars.$identity-blue;
    border-radius: 5px;
    border: 2px solid #1c2b49;
  }
}

.main-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  max-height: calc(100vh - 72px);
}

.main-content {
  overflow-y: auto;
  flex-grow: 1;
  color: black;
  display: flex;

  i {
    color: black;
    margin-right: 5px;
  }

  .main-content-sidebar {
    width: 313px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-top: 86px;
  }

  .main-content-primary {
    flex-grow: 1;
  }
}

.content-wrapper {
  border-radius: 20px;
  background: rgba(1, 14, 45, 0.05);
  margin: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.section-bubble {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 22px 30px;
  margin: 24px 0;
}

.search-bar {
  display: flex;
  padding: 22px 0;
  align-items: baseline;
  justify-content: space-between;

  .search {
    position: relative;
    flex-grow: 0.7;

    input {
      width: 100%;
    }

    i,
    img {
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}

.activity-item {
  position: relative;
  margin-bottom: 50px;

  .ribbon {
    color: #fff;
    position: absolute;
    right: 0;
    border-radius: 0 0 5px 5px;
    background: #ffbe6a;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 21px 3px 4px 3px;
    top: -24px;
    width: 60px;
    text-align: center;

    &.private {
      background: #ffbe6a;
    }

    &.public {
      background: #283857;
    }
  }
}

.account-menu {
  display: flex;
  flex-direction: column;
}

.sign-out {
  display: flex;
  color: vars.$identity-orange;
  padding-top: 12px;

  i {
    color: vars.$identity-orange;
  }
}

.menu-item {
  padding: 12px 0;

  &::after {
    @include mixins.icons();
    content: "\e903";
    float: right;
  }
}

.demos {
  margin-top: 40px;

  > h5 {
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .demo-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 20%;

    > div {
      padding: 7px 14px;
    }
  }
}

// CSS

.activity-panel {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-header h2 {
  font-size: 18px;
  margin: 0;
}

.badge {
  background-color: #ffd966;
  color: black;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 5px;
  text-transform: uppercase;
}

.activity-info {
  margin: 15px 0;
  display: flex;
  gap: 20px;
}

.map-preview {
  flex: 1;
  height: 100px;
  background: #ddd;
  border-radius: 5px;
}

.info-list {
  flex: 2;
}

.info-list div {
  font-size: 14px;
  margin-bottom: 10px;
}

.info-list div span {
  font-weight: bold;
}

.btn-summarize {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-summarize:hover {
  background-color: #0056b3;
}

.section {
  margin-top: 20px;
}

.ongoing-requests {
  margin-top: 10px;
}

.request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.request-item div {
  flex: 1;
  font-size: 14px;
}

.progress-bar {
  flex: 1;
  background: #e9ecef;
  border-radius: 5px;
  position: relative;
  height: 8px;
  margin-left: 10px;
}

.progress-bar span {
  display: block;
  height: 100%;
  border-radius: 5px;
  background: #007bff;
  width: 50%;
}

.notifications {
  margin-top: 20px;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.notification-item .icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.notification-item .data {
  flex: 2;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.icon.red {
  background: red;
}

.icon.yellow {
  background: yellow;
}

.icon.green {
  background: green;
}

a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

a:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;

  .dropdown-header {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 4px;
    color: white;
    font-size: 14px;

    &.inverted {
      background: transparent;
    }
  }

  .dropdown-content {
    background: white;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 100;
    font-size: 14px;

    label {
      display: block;
      margin: 5px 0;

      input {
        margin-right: 5px;
      }
    }

    hr {
      border: none;
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }
  }
}

.switch-activity-dropdown {
  background: vars.$identity-blue;
  padding: 10px;
  border-radius: 4px;
  color: vars.$color-white;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .switch-activity-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #004080;

    &:last-child {
      border-bottom: none;
    }

    .activity-title {
      font-size: 14px;
      font-weight: 500;
    }

    .activity-date {
      font-size: 12px;
      color: #cccccc;
    }

    input[type="checkbox"] {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #004080;
      border-radius: 4px;
      background: white;
      cursor: pointer;
      position: relative;

      &:checked {
        background: #ffd966;
        border-color: #ffd966;
      }

      &:checked::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 6px;
        width: 6px;
        height: 10px;
        border: solid #00264d;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.notification-dropdown {
  background: vars.$identity-blue;
  padding: 10px;
  border-radius: 4px;
  color: white;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 0px;

  .notification-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #004080;
    padding-bottom: 10px;

    &:last-child {
      border-bottom: none;
    }

    .notification-title {
      font-size: 14px;
      font-weight: 500;
    }

    .notification-date {
      font-size: 12px;
      color: #cccccc;
    }

    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-size: contain;
      cursor: pointer;
    }
  }
}

.dropdown-content {
  .setting-dropdown, .profile-dropdown {
    width: 250px;
    background: vars.$identity-blue;
    padding: 10px;
    border-radius: 4px;
    color: vars.$color-white;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    & > div {
      margin-bottom: 10px;
    }
    label {
      margin-left: 100px;
      color: vars.$color-white;
      font-weight: normal;

      input {
        width: auto;
      }
    }
  }

  .profile-dropdown {
    text-align: center;
    color: white;
    
    h6 {
      color: white;
      margin: 5px 0 0;
    }

    small {
      opacity: 0.5;
    }
  }
}

.new-request-btn {
  border: 0px;
  background: vars.$color-orange-btn !important;
  color: white !important;
  margin: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 36px;

  &:hover {
    background: vars.$color-orange-btn-light !important;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80%;
  overflow-x: hidden;

  &.large {
    width: 60%;
  }

  button.close-button,
  .close-button {
    position: absolute;
    top: -8px;
    right: -5px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 20px;

    button {
      margin: 0px;
      width: 100%;
      max-width: 200px;
    }
  }
}

h3 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    width: 120px;
    min-width: 120px;
    margin-bottom: 0px;
  }

  input + button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input:has(+ button) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-members {
  display: flex;
  gap: 10px;
}

.add-members input {
  flex: 1;
}

.add-members button {
  background: #00264d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.members-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  .member {
    display: flex;
    align-items: center;
    background: #f0f0f0;
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
  }

  .member button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    color: vars.$color-border-dark;
    padding: 0px;
    margin: 0px;
    margin-left: 15px;
    height: 10px;
  }
}

// Layout
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col-1 {
  width: 100%;
}

.col-2 {
  width: 50%;
}

.col-4 {
  width: 25%;
}
