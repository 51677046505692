/*******************************
Field Elements
*******************************/
@use "vars";
@use "mixins";

input[type="text"]:not(.MuiInputBase-input),
input[type="email"]:not(.MuiInputBase-input),
input[type="password"]:not(.MuiInputBase-input),
select:not(.MuiInputBase-input),
textarea:not(.MuiInputBase-input),
form > .dropdown:not(.MuiInputBase-input) {
  border-radius: 10px;
  border: 1px solid vars.$identity-blue;
  padding: 5px 20px;
  background-color: #fff;
  height: 40px;

  // Autofill

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background: white !important;
    -webkit-text-fill-color: vars.$color-text;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  // Placeholder styles

  &::placeholder {
    color: vars.$color-text-light;
  }

  &:hover:not(.w-md-editor-text-input):not(.outline) {
    background-color: vars.$color-field-hover;
  }

  &:focus {
    border-color: vars.$color-positive;
    z-index: 2;
  }

  &.xsmall {
    border-radius: 10px;
    height: 36px;
    font-size: 14px;
    padding: 0 12px;
  }

  &.small {
    border-radius: 12px;
    height: 44px;
    font-size: 14px;
    padding: 0 16px;
  }

  &.outline {
    background: transparent;
    border-color: vars.$color-border-dark;
    color: white;

    &:disabled {
      color: rgba(white, 0.5);
    }

    &:focus {
      border-color: vars.$color-positive;
    }
  }

  &.transparent {
    border: none;
    border-radius: 0;

    &:focus {
      border: none;
    }
  }

  &.underlined {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid vars.$color-border;
    padding-left: 0;
    padding-right: 0;
  }
}

textarea {
  min-height: 140px;
  padding-top: 15px;
  line-height: 21px;

  &.large {
    font-size: 18px;
    line-height: 24px;
  }
}

// Label + input combo

.input-group {
  margin-bottom: 20px;
  position: relative;

  > label {
    display: block;
    margin: 0 0 8px;
  }

  input,
  textarea,
  .select,
  select {
    margin: 0;
  }

  // .error {
  //   text-align: right;
  //   margin-left: 20px;
  // }

  .switch-wrapper {
    margin: 0 0 15px;
  }
}

// Double stacked text fields

.text-field-combo {
  margin-bottom: 20px;

  > input[type="text"] {
    margin: 0;

    &:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

// Combination of input and button hovering or next to the field

.input-combo {
  position: relative;
  margin: 20px 0;

  > input {
    position: relative;
    margin: 0;
    z-index: 1;
  }

  &.inline {
    display: flex;
    align-items: center;

    > input {
      margin: 0 10px 0 0;
    }

    .meta,
    label,
    p {
      margin: 0 !important;
    }
  }

  &.over {
    button,
    .button,
    .icon-btn {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
}

// Select Field

.select {
  margin: 20px 0;
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding-right: 40px;
    margin: 0;
  }

  &::after {
    @include mixins.icons();
    content: "\e902";
    font-size: 16px;
    color: vars.$color-text;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
  }

  &.xsmall {
    &::after {
      font-size: 12px;
    }

    select {
      border-radius: 10px;
      height: 32px;
      font-size: 12px;
      padding: 0 12px;
    }
  }

  &.small {
    &::after {
      font-size: 14px;
    }

    select {
      border-radius: 12px;
      height: 44px;
      font-size: 14px;
      padding: 0 18px;
    }
  }

  &.dark {
    &::after {
      color: white;
    }

    select {
      background: rgba(0, 0, 0, 0.15);
      border-color: vars.$color-border-dark;
      color: white;

      &:hover {
        background-color: #021d29;
      }
    }
  }
}

// Select icon-only Field

.select-icon {
  position: relative;
  width: 20px;
  height: 20px;

  > i {
    display: block;
    font-size: 16px;
    pointer-events: none;
    position: relative;
    z-index: 1;
    line-height: 20px;
    width: inherit;
    height: inherit;
  }

  > select {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    line-height: 20px;
    width: inherit;
    height: inherit;
    position: absolute;
    outline: none;
    top: 0;
    left: 0;
    z-index: 2;
    margin: 0;
  }
}

// Custom Checkbox and Radio buttons

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  > label {
    margin: 0 0 0 8px;
    font-weight: vars.$medium !important;
    color: vars.$color-text;
  }

  .checkbox,
  .radio {
    position: relative;
    user-select: none;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;

    > span {
      background-color: white;
      border: 1px solid vars.$color-text-light;
      cursor: pointer;
      display: block;
      position: absolute;
      inset: 0;
      border-radius: 6px;

      .icon-checkmark {
        color: white;
        display: none;
        font-size: 20px;
        margin: 1px;
      }
    }

    // Hide the browser's default checkbox
    input {
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      // When the checkbox is checked, add a blue background
      &:checked + span {
        background-color: vars.$color-positive;
        border-color: vars.$color-positive;

        .icon-checkmark {
          display: block;
        }
      }

      // Show the checkmark when checked
      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    &.small {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;

      > span .icon-checkmark {
        font-size: 16px;
      }
    }
  }

  .radio {
    > span {
      border-radius: 50%;
    }

    input:checked + span::after {
      background: vars.$color-positive;
      border: 1px solid white;
      border-radius: 50%;
      display: block;
      content: "";
      width: 18px;
      height: 18px;
      position: absolute;
      inset: 1px;
    }
  }
}

// On/off Switch

.switch-wrapper {
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-right: 65px;
  margin-bottom: 20px;

  > label {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: vars.$color-text;
    margin: 0;

    + .meta {
      display: block;
      margin-top: 5px;
    }
  }

  .switch {
    width: 42px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;

    input[type="checkbox"] {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 3;
    }

    span {
      display: block;
    }

    input + span {
      background-color: vars.$color-text-light;
      border-radius: 50%;
      display: block;
      position: absolute;
      z-index: 2;
      width: 16px;
      height: 16px;
      top: 4px;
      left: 4px;
      transition: left 150ms, background-color 150ms;

      + span {
        background-color: transparent;
        border: 1px solid vars.$color-text-light;
        border-radius: 14px;
        width: inherit;
        height: inherit;
        position: relative;
        transition: border-color 150ms, background-color 150ms;
      }
    }

    input[type="checkbox"]:checked {
      + span {
        background: white;
        left: calc(100% - 20px);

        + span {
          background-color: vars.$color-positive;
          border-color: vars.$color-positive;
        }
      }
    }
  }
}

// Color Picker

.input-color-picker {
  position: relative;

  > input[type="text"] {
    font-family: vars.$font-family-mono;
    position: relative;
    z-index: 1;
  }

  .input-color-swatch {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
  }

  .color-picker {
    background: white;
    border-radius: 10px;
    box-shadow: 0 7px 13px 0 rgba(black, 0.14), 0 0 1px 1px vars.$color-border;
    position: absolute;
    z-index: 99;
    right: 5px;
    margin-top: 5px;
    padding: 10px;

    .chrome-picker {
      box-shadow: none !important;

      > div {
        border-radius: 5px !important;
      }
    }
  }
}

// Custom Dropdown

.dropdown {
  display: flex;
  position: relative;
  z-index: 3;
  margin: 0 10px;

  > i {
    margin-right: 11px;
    margin-left: 11px;
  }

  /*&::after {
    @include icons();
    content: "\e902";
    font-size: 14px;
    color: inherit;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
  }*/

  input[type="text"] {
    cursor: pointer;
    position: relative;
  }

  .dropdown-options {
    box-shadow: 0 18px 35px -5px rgba(0, 0, 0, 0.1), 0 0 1px 1px rgba(0, 0, 0, 0.05);
    background: white;
    border-radius: 14px;
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    overflow: auto;
    max-height: 240px;

    .option {
      font-size: 18px;
      color: vars.$color-text;
      cursor: pointer;
      padding: 8px 16px;

      &:hover {
        background: vars.$color-off-white;
      }
    }

    &.active {
      display: block;
    }
  }
}
