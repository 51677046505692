@use "vars";

.main-content.new-request-wrapper {
  background-color: vars.$color-new-request-bg;
  display: flex;
  flex-direction: column;

  .new-request, .confirmation-page {
    width: 100%;
    font-family: Arial, sans-serif;
    flex-grow: 1;
    padding: 36px 24px;
    overflow-y: auto;

    .step {
      >span {
        visibility: hidden;
      }

      &.active {
        border: 4px solid vars.$identity-blue;
      }
    }

    div[class^="StepContainer"] {
      div[class^="StepButton"] {

        &.active {
          border: 4px solid vars.$identity-blue;
        }
      }

      div[class^="LabelContainer"] {
        >span {
          color: vars.$identity-blue;
          font-weight: bold;
        }
      }
    }

    .step-content {
      margin-bottom: 20px;

      h3 {
        margin-top: 50px;
        margin-bottom: 30px;
        color: vars.$identity-blue;
      }

      label {
        display: block;
      }

      input[type="date"],
      select,
      textarea {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 1px 1px 0px #00000040;
      }

      // Step 1 - Activity selection
      .request-scope-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-evenly;

        .request-scope-container {
          cursor: pointer;
          width: 150px;
          height: 150px;
          border: 1px solid vars.$identity-blue;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          opacity: 0.5;

          &.active {
            opacity: 1;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          }
        }

        .request-scope-text {
          padding: 10px;
          text-align: center;
          background-color: vars.$color-white;
          font-size: 13px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      // Step 1 - Activity selection
      label {
        display: flex;
        align-items: center;

        input[type="radio"], input[type="checkbox"] {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }

      .data-type-container {
        background-color: vars.$color-white;
        border-radius: 20px;
        margin-top: 50px;
        padding: 20px 40px;
        box-shadow: 1px 1px 2px 0px #00000040;
  
        label {
          color: vars.$identity-blue;
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
          width: 120px;
          justify-content: center;
        }
  
        input {
          display: none;
        }
      }

      .request-type {
        margin-bottom: 30px;
  
        h4 {
          margin-bottom: 10px;
        }
  
        .checkbox-group {
          display: flex;
          gap: 15px;
        }
  
        .checkbox {
          display: flex;
          align-items: center;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.3s ease;
  
          &.selected {
            border-color: #00264d;
            background-color: #f0f8ff;
          }
  
          input {
            margin-right: 10px;
          }
        }
      }

      .request-priority-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-evenly;
        max-width: 500px;

        .request-priority-container {
          cursor: pointer;
          background-color: vars.$color-white;
          width: 150px;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          box-shadow: 1px 1px 2px 0px #00000040;
          justify-content: center;
          border: 1px solid transparent;

          &.active {
            border: 1px solid vars.$identity-blue;
          }
        }

        .request-priority-text {
          padding: 10px;
          text-align: center;
          font-size: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      // Step 2 - Area selection

      .area-container {
        background-color: vars.$color-white;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 2px 0px #00000040;
        border-radius: 8px;
        color: vars.$identity-blue;
        max-width: 300px;
        cursor: pointer;

        input {
          width: 20px;
          height: 20px;
          margin: 15px;
        }
      }

      .area-map {
        height: 200px;
        background-color: vars.$color-border;
        margin: 20px;
        margin-top: 0px;
      }

      .geometry-info {
        padding: 5px 30px;
        background-color: #010E2D;
        color: white;
        display: flex;
        justify-content: space-between;
      }

      .mapboxgl-ctrl-attrib,
      .mapboxgl-ctrl-logo,
      .mapbox-improve-map {
        display: none !important;
      }

      .wrapper-time {
        display: flex;
        align-items: center;
        gap: 10px;
      
        label {
          font-weight: bold;
          margin-right: 5px;
        }

        input {
          max-width: 300px;
        }
      
        input[type="text"] {
          width: 150px;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 14px;
        }
      
        a {
          color: vars.$color-border-dark;
          font-size: 14px;
          text-decoration: none;
          margin-left: 10px;
      
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    // Step 3 - Objective
    .objective-wrapper {
      .file-extra-info {
        border: 1px solid vars.$color-border-dark;
        border-radius: 8px;
      }

      .desc-extra-info {
        border: 1px solid vars.$color-border-dark;
        border-radius: 8px;
        margin-top: 20px;
        background-color: vars.$color-new-request-bg;
      }
    }

    // Step 4 - Existing issue
    .existing-issue {
      border-radius: 20px;
      margin-top: 30px;
      padding: 20px 40px;
      border-collapse: collapse;

      th, td {
        border-bottom: 1px solid #ddd;
        border-left: none; border-right: none;
      }
    }

    // Step 5 - Existing Data
    

    // Step 6 - Summary

    .summary {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .summary-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: vars.$color-white;
        border-radius: 20px;
        margin-top: 30px;
        padding: 20px;
        box-shadow: 1px 1px 2px 0px #00000040;

        span {
          flex: 1;
          margin-right: 10px;
        }

        .edit-button {
          background-color: transparent;
          border: none;
          border-radius: 3px;
          padding: 5px 10px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease;
        }
      }
    }
  }

  // NAVIGATION
  .navigation {
    display: flex;
    justify-content: space-between;
    background-color: vars.$color-white;
    padding: 36px 24px;

    button {
      padding: 10px 20px;
      background: vars.$color-orange-btn;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      color: vars.$color-white;
      fill: vars.$color-white;

      i {
        color: vars.$color-white;
        fill: vars.$color-white;
      }

      &.round {
        padding: 0;
        margin: 0;
        border-radius: 40px;
        width: 40px;
        height: 40px;
      }

      &.link {
        background: vars.$color-white;
        border: 2px solid vars.$color-orange-btn;
        color: vars.$color-orange-btn;
        font-weight: bold;
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }

    .navigation-right {
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }
  }

  .step-activity-selection {
    h3 {
      margin-bottom: 15px;
    }

    select {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 20px;
    }

    .request-options {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .request-option {
        width: 200px;
        height: 150px;
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.selected {
          border: 2px solid #00264d;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        }

        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
        }

        div {
          padding: 10px;
          text-align: center;
        }
      }
    }
  }

  .step-existing-issue {
    margin-top: 20px;

    h5 {
      margin-bottom: 10px;
    }

    label {
      margin-right: 10px;
    }

    button {
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 3px;
      background: #ffd966;
      border: none;
      cursor: pointer;
    }
  }

  .step-existing-data {
    margin-top: 20px;

    h4 {
      margin-bottom: 10px;
    }

    label {
      margin-right: 10px;
    }

    .checkbox-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      label {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        width: 120px;
        justify-content: center;

        &.selected {
          border: 2px solid #00264d;
        }
      }
    }
  }

  .step-confirm {
    font-family: Arial, sans-serif;
    padding: 20px;

    h3 {
      margin-bottom: 20px;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .back-button,
      .submit-button {
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        border: none;
        transition: background-color 0.3s ease;
      }

      .back-button {
        background-color: #ccc;

        &:hover {
          background-color: #bbb;
        }
      }

      .submit-button {
        background-color: #00264d;
        color: #fff;

        &:hover {
          background-color: #001f3a;
        }
      }
    }
  }
}
